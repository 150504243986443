<template>
  <div class="content"><slot></slot></div>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style scoped>
.content {
  padding: 8px 16px;
  background: var(--kermony-office-beige);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
</style>
