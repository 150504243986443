import _ from "lodash";

export const toLegends = (labels, colors, avatars = []) => {
  return _.map(labels, (arr, index) => ({
    label: _.first(arr),
    value: _.last(arr),
    color: _.get(colors, index, null),
    avatar: _.get(avatars, index, null),
  }));
};

// eslint-disable-next-line no-unused-vars
const tooltip = {
  // Disable the on-canvas tooltip
  enabled: false,

  external: function (context) {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.style =
        "padding: 8px;\n" +
        "    background: #21244E;\n" +
        "    border-radius: 5px;\n" +
        "    color: white;";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    function getBody(bodyItem) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = "<thead>";

      titleLines.forEach(function (title) {
        innerHtml += "<tr><th>" + title + "</th></tr>";
      });
      innerHtml += "</thead><tbody>";

      bodyLines.forEach(function (body, i) {
        const colors = tooltipModel.labelColors[i];
        let style = "background:" + colors.backgroundColor;
        style += "; border-color:" + colors.borderColor;
        style += "; border-width: 2px";
        const span = '<span style="' + style + '"></span>';

        body.forEach(function (body2) {
          innerHtml += "<tr><td>" + span + body2 + "</td></tr>";
        });
      });
      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    // eslint-disable-next-line no-undef
    const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = "absolute";
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding =
      tooltipModel.padding + "px " + tooltipModel.padding + "px";
    tooltipEl.style.pointerEvents = "none";
  },
};

// eslint-disable-next-line no-unused-vars
const plugin = {
  id: "custom_canvas_background_color",
  beforeDraw: (chart) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "lightGreen";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const getChartConfig = (data) => {
  // Chart config
  return {
    type: "doughnut",
    /*
        plugins: [
          {
            afterUpdate: function (chart) {
              const arcs = chart.getDatasetMeta(0).data;

              arcs.forEach(function (arc) {
                arc.round = {
                  x: (chart.chartArea.left + chart.chartArea.right) / 2,
                  y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                  radius: (arc.outerRadius + arc.innerRadius) / 2,
                  thickness: (arc.outerRadius - arc.innerRadius) / 2,
                  backgroundColor: arc.options.backgroundColor,
                };
              });
            },
            afterDraw: (chart) => {
              const { ctx } = chart;

              chart.getDatasetMeta(0).data.forEach((arc) => {
                //const startAngle = Math.PI / 2 - arc.startAngle;
                const endAngle = Math.PI / 2 - arc.endAngle;

                ctx.save();
                ctx.translate(arc.round.x, arc.round.y);
                ctx.fillStyle = arc.options.backgroundColor;
                ctx.beginPath();
                ctx.arc(
                  arc.round.radius * Math.sin(endAngle),
                  arc.round.radius * Math.cos(endAngle),
                  arc.round.thickness,
                  0,
                  2 * Math.PI
                );
                ctx.closePath();
                ctx.fill();
                ctx.restore();
              });
            },
          },
        ],

         */
    //type: "pie",
    data: data,

    options: {
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      cutout: 68,
      //rotation: 45,

      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: false,
        },
        tooltip,
      },
      //responsive: true,
    },
    defaults: {
      global: {},
    },
  };
};
