<template>
  <div class="container-chart">
    <div class="container-chart-and-total">
      <TotalChart
        class="total-chart"
        v-if="total !== null && total !== undefined"
        >{{ total }}</TotalChart
      >
      <div class="wrapper-chart">
        <slot></slot>
      </div>
    </div>
    <Legends :items="legends" :scrollable="legendsScrollable"></Legends>
  </div>
</template>

<script>
import TotalChart from "@/views/App/Transmission/Components/TotalChart";
import Legends from "@/views/App/Transmission/Components/Legends";
export default {
  name: "ChartContainer",
  components: { Legends, TotalChart },
  props: ["total", "legends", "legendsScrollable"],
};
</script>

<style scoped>
.container-chart {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  grid-template-columns: auto minmax(0, 1fr);
}
.container-chart-and-total {
  position: relative;
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-chart {
    grid-auto-flow: row;
    grid-template-columns: inherit;
  }

  .container-chart-and-total {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }
}

.wrapper-chart {
  height: 153px;
  width: 153px;
}

.total-chart {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
</style>
