<template>
  <div class="total-chart">
    <Body2>Total</Body2>
    <Body1><slot></slot></Body1>
  </div>
</template>

<script>
import Body2 from "@/components/Texts/Body2";
import Body1 from "@/components/Texts/Body1";
import { formatAmount } from "@/utils/number";
export default {
  name: "TotalChart",
  components: { Body1, Body2 },
  data() {
    return {
      formatAmount,
    };
  },
};
</script>

<style scoped>
.total-chart {
  display: grid;
  text-align: center;
  width: 153px;
}
</style>
