<template>
  <span class="body2"><slot></slot></span>
</template>

<script>
export default {
  name: "Body2",
};
</script>

<style scoped>
.body2 {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.25px;
  color: var(--kermony-gris-2);
}
</style>
